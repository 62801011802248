<template>
  <v-container grid-list-xs v-if="!loading && session && chef">
    <template v-if="streamAvailable">
      <v-layout align-space-around row wrap fill-height>
        <v-flex xs11 sm8>
          <div id="session" @error="errorHandler">
            <div
              id="subscribers"
              v-for="stream in streams"
              :key="stream.streamId"
            >
              <subscriber
                @error="errorHandler"
                :stream="stream"
                :opts="viewOptions"
                :session="session"
              ></subscriber>
            </div>
          </div>
          <div v-if="streamData && streamData.description">
            Chef's Note:
            <pre> {{ streamData.description }}</pre>
          </div>
        </v-flex>
        <v-flex xs12 sm4 v-if="dish">
          <h4>Presenting :</h4>
          <food-view :dish="dish" />
        </v-flex>
      </v-layout>
    </template>
    <template v-else>
      <v-layout row v-if="!streamEnded" wrap>
        <v-flex xs11>
          <v-alert
            type="info"
            :value="true"
            icon="fas fa-spinner fa-spin"
            outlined
          >
            Stream is not available yet. Video will be shown as soon as steam
            available
          </v-alert>
        </v-flex>
      </v-layout>
    </template>
    <template v-if="profile && streamEnded">
      <v-layout row wrap>
        <v-flex xs12 sm6>
          <section>
            <v-icon large color="warning darken-2">warning</v-icon>

            <span class="text-md-center headline"> The stream has ended.</span>
          </section>
        </v-flex>
      </v-layout>
      <v-layout row class="mt-5" wrap>
        <v-flex xs12>
          Want to rate or leave a comment for this broadcast?
          <v-rating
            v-model="rating"
            color="yellow darken-3"
            :readonly="feedbackSent"
            background-color=" darken-1"
            empty-icon="$vuetify.icons.ratingFull"
            hover
          ></v-rating>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs11 sm7>
          <v-textarea
            outlined
            :disabled="feedbackSent"
            label="Comments"
            v-model="comments"
            counter
            name="comments"
            rows="2"
          ></v-textarea>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs11 sm7>
          <v-btn
            color="success"
            :disabled="feedbackSent || (comments.length < 5 && rating == 0)"
            @click.prevent="submitFeedback"
            >Submit</v-btn
          >
        </v-flex>
      </v-layout>
    </template>

    <v-layout row wrap>
      <v-flex xs12 sm6> <chef-view :selectedChef="chef" /> </v-flex>
    </v-layout>
    <v-layout row v-if="profile" wrap>
      <v-flex xs12 sm6>
        <chat
          :profile="profile"
          :chatParticipants="chatParticipants"
          :incomingMessage="incomingMessage"
          @sendMessage="sendMessage"
        />
      </v-flex>
    </v-layout>
    <v-layout v-else row wrap>
      <v-flex xs9 sm7>
        Want to send a message?
        <router-link to="/signup">Sign up</router-link> or
        <router-link to="/signin">Sign In</router-link>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import fod_config from '@/config/config'
import OT from '@opentok/client'
import Subscriber from './Subscriber.vue'
import { mapGetters } from 'vuex'
import Chat from './Chat'
import FoodView from '@/components/cook/public/FoodView'
import ChefView from '@/components/profile/public/ChefView'

const errorHandler = err => {
  console.error(err.message)
}

export default {
  name: 'watch',
  data: () => ({
    chefId: null,
    streamId: null,
    rating: 0,
    comments: '',
    feedbackSent: false,
    roomId: null,
    docRef: null,
    streams: [],
    viewOptions: null,
    session: null,
    incomingMessage: []
  }),
  components: { Subscriber, Chat, FoodView, ChefView },
  computed: {
    ...mapGetters({
      dish: 'dish',
      chef: 'chef',
      profile: 'profile',
      user: 'user',
      chatParticipants: 'chatParticipants',
      streamSession: 'streamSession',
      streamData: 'streamData',
      loading: 'loading'
    }),
    streamAvailable() {
      return this.streams.length > 0
    },
    streamEnded() {
      return this.streamData && this.streamData.status === 'ended'
    }
  },
  mounted() {
    this.chefId = this.$route.params.id
    this.streamId = this.$route.query.stream_id
    this.roomId = this.chefId || this.$route.query.room
    if (this.streamId) {
      this.docRef = this.$store.dispatch('loadStream', {
        chefId: this.chefId,
        streamId: this.streamId
      })
    } else {
      this.$store.dispatch('setError', { message: 'Stream is not available' })
      return
    }
    this.apiKey = fod_config.STREAM_API_KEY
    this.$store.dispatch('getStreamSession', this.roomId).then(sessionData => {
      console.log('Subscriber starting a session from: ', sessionData)
      this.session = OT.initSession(this.apiKey, this.streamSession.sessionId)
      this.session.on('streamCreated', event => {
        console.log(`Stream Created ${this.roomId}`)

        // TODO: this should be removed if there are multiple streams
        this.streams = []
        this.streams.push(event.stream)
      })
      this.session.connect(this.streamSession.token, err => {
        if (err) {
          errorHandler(err)
        } else {
          console.log('Stream Connected ', this.roomId)
        }
      })
      this.session.on('streamDestroyed', event => {
        console.log('Stream Destroyed ', this.roomId)
        const idx = this.streams.indexOf(event.stream)
        if (idx > -1) {
          this.streams.splice(idx, 1)
        }
      })
      //this.incomingMessage = []
      this.$store.dispatch('loadChefById', this.chefId)
      var store = this.$store
      var session = this.session
      var incomingMessage = this.incomingMessage
      var chef = this.chef
      this.session.on('signal:msg', function(event) {
        var payload = event.data
        if (!payload) {
          return
        }
        var caller =
          event.from.connectionId === session.connection.connectionId
            ? 'mine'
            : 'theirs'

        if (event.from.connectionId != session.connection.connectionId) {
          let data = {
            author: payload.name,
            type: 'text',
            data: { text: event.data.message.text }
          }
          console.log('message arrived:', payload)

          incomingMessage.length = 0
          incomingMessage.push(data)
          store.dispatch('updateChatParticipants', payload)
        }
      })
    })
    this.viewOptions = {
      width: '100%',
      height: 450
    }
  },

  beforeDestroy() {
    console.log('Disconnecting')
    this.session.disconnect()

    if (this.docRef) {
      // Stop listening to changes
      this.docRef.then(unsubscribe => {
        unsubscribe()
      })
    }
  },

  methods: {
    errorHandler,
    sendMessage(message) {
      console.log('Sending message:', message)
      if (this.session) {
        this.session.signal(
          {
            type: 'msg',
            data: {
              message: message,
              userId: this.user.id,
              name: this.profile.name,
              imageUrl: this.profile.avatar
            }
          },
          function(error) {
            if (error) {
              console.log('Error sending signal:', error.name, error.message)
            }
          }
        )
      }
    },
    submitFeedback() {
      this.feedbackSent = true
      this.$store.dispatch('setMessage', {
        title: 'Thank you',
        body: 'For rating and comments.'
      })
    }
  }
}
</script>
